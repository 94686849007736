// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-current-members-js": () => import("./../../../src/pages/current-members.js" /* webpackChunkName: "component---src-pages-current-members-js" */),
  "component---src-pages-former-members-js": () => import("./../../../src/pages/former-members.js" /* webpackChunkName: "component---src-pages-former-members-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-templates-blog-post-contentful-js": () => import("./../../../src/templates/blog-post-contentful.js" /* webpackChunkName: "component---src-templates-blog-post-contentful-js" */)
}

